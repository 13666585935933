export const getUiActionElements = ({ themeColors, spaces, radii }) => ({
  radius: {
    oval: radii.full,
    small: radii.small,
  },

  sizes: {
    nano: {
      px: spaces.paddings.nanoPaddingX,
      pt: spaces.paddings.nanoPaddingTop,
      pb: spaces.paddings.nanoPaddingBottom,
    },
    nanoOval: {
      px: spaces.paddings.nanoPaddingXOval,
      pt: spaces.paddings.nanoPaddingTop,
      pb: spaces.paddings.nanoPaddingBottom,
    },
    tiny: {
      px: spaces.paddings.tinyPaddingX,
      pt: spaces.paddings.tinyPaddingTop,
      pb: spaces.paddings.tinyPaddingBottom,
    },
    tinyOval: {
      px: spaces.paddings.tinyPaddingXOval,
      pt: spaces.paddings.tinyPaddingTop,
      pb: spaces.paddings.tinyPaddingBottom,
    },
    small: {
      px: spaces.paddings.smallPaddingX,
      pt: spaces.paddings.smallPaddingTop,
      pb: spaces.paddings.smallPaddingBottom,
    },
    smallOval: {
      px: spaces.paddings.smallPaddingXOval,
      pt: spaces.paddings.smallPaddingTop,
      pb: spaces.paddings.smallPaddingBottom,
    },
    medium: {
      px: spaces.paddings.mediumPaddingX,
      pt: spaces.paddings.mediumPaddingTop,
      pb: spaces.paddings.mediumPaddingBottom,
    },
    mediumOval: {
      px: spaces.paddings.mediumPaddingXOval,
      pt: spaces.paddings.mediumPaddingTop,
      pb: spaces.paddings.mediumPaddingBottom,
    },
    large: {
      px: spaces.paddings.largePaddingX,
      pt: spaces.paddings.largePaddingTop,
      pb: spaces.paddings.largePaddingBottom,
    },
    largeOval: {
      px: spaces.paddings.largePaddingXOval,
      pt: spaces.paddings.largePaddingTop,
      pb: spaces.paddings.largePaddingBottom,
    },
  },

  dark: {
    default: {
      bg: themeColors.dark.default.bg,
      color: themeColors.dark.default.color,
    },
    disabled: {
      bg: themeColors.dark.disabled.bg,
      color: themeColors.dark.disabled.color,
    },
    focus: {
      bg: themeColors.dark.focus.bg,
      color: themeColors.dark.focus.color,
    },
    hover: {
      bg: themeColors.dark.hover.bg,
      color: themeColors.dark.hover.color,
    },
    placeholder: {
      color: themeColors.dark.placeholder.color,
    },
    progress: {
      bg: themeColors.dark.progress.bg,
      borderColor: themeColors.dark.progress.borderColor,
      color: themeColors.dark.progress.color,
    },
  },

  ghost: {
    default: {
      bg: themeColors.ghost.default.bg,
      color: themeColors.ghost.default.color,
    },
    disabled: {
      bg: themeColors.ghost.disabled.bg,
      color: themeColors.ghost.disabled.color,
    },
    focus: {
      bg: themeColors.ghost.focus.bg,
      color: themeColors.ghost.focus.color,
    },
    hover: {
      bg: themeColors.ghost.hover.bg,
      color: themeColors.ghost.hover.color,
    },
    placeholder: {
      color: themeColors.ghost.placeholder.color,
    },
    progress: {
      bg: themeColors.ghost.progress.bg,
      borderColor: themeColors.ghost.progress.borderColor,
      color: themeColors.ghost.progress.color,
    },
  },

  gray: {
    default: {
      bg: themeColors.gray.default.bg,
      color: themeColors.gray.default.color,
    },
    disabled: {
      bg: themeColors.gray.disabled.bg,
      color: themeColors.gray.disabled.color,
    },
    focus: {
      bg: themeColors.gray.focus.bg,
      color: themeColors.gray.focus.color,
    },
    hover: {
      bg: themeColors.gray.hover.bg,
      color: themeColors.gray.hover.color,
    },
    placeholder: {
      color: themeColors.gray.placeholder.color,
    },
    progress: {
      bg: themeColors.gray.progress.bg,
      borderColor: themeColors.gray.progress.borderColor,
      color: themeColors.gray.progress.color,
    },
  },

  light: {
    default: {
      bg: themeColors.light.default.bg,
      color: themeColors.light.default.color,
    },
    disabled: {
      bg: themeColors.light.disabled.bg,
      color: themeColors.light.disabled.color,
    },
    focus: {
      bg: themeColors.light.focus.bg,
      color: themeColors.light.focus.color,
    },
    hover: {
      bg: themeColors.light.hover.bg,
      color: themeColors.light.hover.color,
    },
    placeholder: {
      color: themeColors.light.placeholder.color,
    },
    progress: {
      bg: themeColors.light.progress.bg,
      borderColor: themeColors.light.progress.borderColor,
      color: themeColors.light.progress.color,
    },
  },

  lightgray: {
    checked: {
      bg: themeColors.lightgray.checked.bg,
      color: themeColors.lightgray.checked.color,
    },
    default: {
      bg: themeColors.lightgray.default.bg,
      color: themeColors.lightgray.default.color,
    },
    disabled: {
      bg: themeColors.lightgray.disabled.bg,
      color: themeColors.lightgray.disabled.color,
    },
    focus: {
      bg: themeColors.lightgray.focus.bg,
      color: themeColors.lightgray.focus.color,
    },
    hover: {
      bg: themeColors.lightgray.hover.bg,
      color: themeColors.lightgray.hover.color,
    },
    placeholder: {
      color: themeColors.lightgray.placeholder.color,
    },
    progress: {
      bg: themeColors.lightgray.progress.bg,
      borderColor: themeColors.lightgray.progress.borderColor,
      color: themeColors.lightgray.progress.color,
    },
  },

  primary: {
    default: {
      bg: themeColors.primary.default.bg,
      color: themeColors.primary.default.color,
    },
    checked: {
      bg: themeColors.primary.checked.bg,
      color: themeColors.primary.checked.color,
    },
    disabled: {
      bg: themeColors.primary.disabled.bg,
      color: themeColors.primary.disabled.color,
    },
    focus: {
      bg: themeColors.primary.focus.bg,
      color: themeColors.primary.focus.color,
    },
    hover: {
      bg: themeColors.primary.hover.bg,
      color: themeColors.primary.hover.color,
    },
    placeholder: {
      color: themeColors.primary.placeholder.color,
    },
    progress: {
      bg: themeColors.primary.progress.bg,
      borderColor: themeColors.primary.progress.borderColor,
      color: themeColors.primary.progress.color,
    },
  },

  primaryGhost: {
    default: {
      bg: themeColors.primaryGhost.default.bg,
      color: themeColors.primaryGhost.default.color,
    },
    disabled: {
      bg: themeColors.primaryGhost.disabled.bg,
      color: themeColors.primaryGhost.disabled.color,
    },
    focus: {
      bg: themeColors.primaryGhost.focus.bg,
      color: themeColors.primaryGhost.focus.color,
    },
    hover: {
      bg: themeColors.primaryGhost.hover.bg,
      color: themeColors.primaryGhost.hover.color,
    },
    placeholder: {
      color: themeColors.primaryGhost.placeholder.color,
    },
    progress: {
      bg: themeColors.primaryGhost.progress.bg,
      borderColor: themeColors.primaryGhost.progress.borderColor,
      color: themeColors.primaryGhost.progress.color,
    },
  },
  primaryShallow: {
    default: {
      bg: themeColors.primaryShallow.default.bg,
      color: themeColors.primaryShallow.default.color,
    },
    disabled: {
      bg: themeColors.primaryShallow.disabled.bg,
      color: themeColors.primaryShallow.disabled.color,
    },
    focus: {
      bg: themeColors.primaryShallow.focus.bg,
      color: themeColors.primaryShallow.focus.color,
    },
    hover: {
      bg: themeColors.primaryShallow.hover.bg,
      color: themeColors.primaryShallow.hover.color,
    },
    placeholder: {
      color: themeColors.primaryShallow.placeholder.color,
    },
    progress: {
      bg: themeColors.primaryShallow.progress.bg,
      borderColor: themeColors.primaryShallow.progress.borderColor,
      color: themeColors.primaryShallow.progress.color,
    },
  },
  white: {
    default: {
      bg: themeColors.white.default.bg,
      borderColor: themeColors.white.default.borderColor,
      color: themeColors.white.default.color,
    },
    checked: {
      bg: themeColors.white.checked.bg,
      color: themeColors.white.checked.color,
      borderColor: themeColors.white.checked.borderColor,
    },
    disabled: {
      bg: themeColors.white.disabled.bg,
      borderColor: themeColors.white.disabled.borderColor,
      color: themeColors.white.disabled.color,
    },
    focus: {
      bg: themeColors.white.focus.bg,
      borderColor: themeColors.white.focus.borderColor,
      color: themeColors.white.focus.color,
    },
    hover: {
      bg: themeColors.white.hover.bg,
      borderColor: themeColors.white.hover.borderColor,
      color: themeColors.white.hover.color,
    },
    placeholder: {
      bg: themeColors.white.placeholder.bg,
      borderColor: themeColors.white.placeholder.borderColor,
      color: themeColors.white.placeholder.color,
    },
    progress: {
      bg: themeColors.white.progress.bg,
      borderColor: themeColors.white.progress.borderColor,
      color: themeColors.white.progress.color,
    },
  },
  whiteBare: {
    default: {
      bg: themeColors.whiteBare.default.bg,
      borderColor: themeColors.whiteBare.default.borderColor,
      color: themeColors.whiteBare.default.color,
    },
    checked: {
      bg: themeColors.whiteBare.checked.bg,
      color: themeColors.whiteBare.checked.color,
      borderColor: themeColors.whiteBare.checked.borderColor,
    },
    disabled: {
      bg: themeColors.whiteBare.disabled.bg,
      borderColor: themeColors.whiteBare.disabled.borderColor,
      color: themeColors.whiteBare.disabled.color,
    },
    focus: {
      bg: themeColors.whiteBare.focus.bg,
      borderColor: themeColors.whiteBare.focus.borderColor,
      color: themeColors.whiteBare.focus.color,
    },
    hover: {
      bg: themeColors.whiteBare.hover.bg,
      borderColor: themeColors.whiteBare.hover.borderColor,
      color: themeColors.whiteBare.hover.color,
    },
    placeholder: {
      bg: themeColors.whiteBare.placeholder.bg,
      borderColor: themeColors.whiteBare.placeholder.borderColor,
      color: themeColors.whiteBare.placeholder.color,
    },
    progress: {
      bg: themeColors.whiteBare.progress.bg,
      borderColor: themeColors.whiteBare.progress.borderColor,
      color: themeColors.whiteBare.progress.color,
    },
  },

  success: {
    default: {
      bg: themeColors.success.default.bg,
      color: themeColors.success.default.color,
    },
    disabled: {
      bg: themeColors.success.disabled.bg,
      color: themeColors.success.disabled.color,
    },
    focus: {
      bg: themeColors.success.focus.bg,
      color: themeColors.success.focus.color,
    },
    hover: {
      bg: themeColors.success.hover.bg,
      color: themeColors.success.hover.color,
    },
    placeholder: {
      color: themeColors.success.default.color,
    },
    progress: {
      bg: themeColors.success.default.bg,
      borderColor: themeColors.success.default.bg,
      color: themeColors.success.default.color,
    },
  },

  error: {
    default: {
      bg: themeColors.error.default.bg,
      color: themeColors.error.default.color,
    },
    disabled: {
      bg: themeColors.error.disabled.bg,
      color: themeColors.error.disabled.color,
    },
    focus: {
      bg: themeColors.error.focus.bg,
      color: themeColors.error.focus.color,
    },
    hover: {
      bg: themeColors.error.hover.bg,
      color: themeColors.error.hover.color,
    },
    placeholder: {
      color: themeColors.error.default.color,
    },
    progress: {
      bg: themeColors.error.default.bg,
      borderColor: themeColors.error.default.bg,
      color: themeColors.error.default.color,
    },
  },
  errorGhost: {
    default: {
      bg: themeColors.errorGhost.default.bg,
      color: themeColors.errorGhost.default.color,
      borderColor: themeColors.errorGhost.default.borderColor,
    },
    disabled: {
      bg: themeColors.errorGhost.disabled.bg,
      color: themeColors.errorGhost.disabled.color,
      borderColor: themeColors.errorGhost.disabled.borderColor,
    },
    focus: {
      bg: themeColors.errorGhost.focus.bg,
      color: themeColors.errorGhost.focus.color,
      borderColor: themeColors.errorGhost.focus.borderColor,
    },
    hover: {
      bg: themeColors.errorGhost.hover.bg,
      color: themeColors.errorGhost.hover.color,
      borderColor: themeColors.errorGhost.hover.borderColor,
    },
    placeholder: {
      color: themeColors.errorGhost.placeholder.color,
    },
    progress: {
      bg: themeColors.errorGhost.progress.bg,
      borderColor: themeColors.errorGhost.progress.borderColor,
      color: themeColors.errorGhost.progress.color,
    },
  },
  errorShallow: {
    default: {
      bg: themeColors.errorShallow.default.bg,
      color: themeColors.errorShallow.default.color,
      borderColor: themeColors.errorShallow.default.borderColor,
    },
    disabled: {
      bg: themeColors.errorShallow.disabled.bg,
      color: themeColors.errorShallow.disabled.color,
      borderColor: themeColors.errorShallow.disabled.borderColor,
    },
    focus: {
      bg: themeColors.errorShallow.focus.bg,
      color: themeColors.errorShallow.focus.color,
      borderColor: themeColors.errorShallow.focus.borderColor,
    },
    hover: {
      bg: themeColors.errorShallow.hover.bg,
      color: themeColors.errorShallow.hover.color,
      borderColor: themeColors.errorShallow.hover.borderColor,
    },
    placeholder: {
      color: themeColors.errorShallow.placeholder.color,
    },
    progress: {
      bg: themeColors.errorShallow.progress.bg,
      borderColor: themeColors.errorShallow.progress.borderColor,
      color: themeColors.errorShallow.progress.color,
    },
  },
})
