import { zIndices } from 'config/zIndices'
import { getThemeValue } from 'theming'

export const getStyleHeader = (theme) => {
  return {
    // backgroundColor: theme.table.header.bg,
    // backgroundColor: 'orange',
    color: theme.table.header.color,
  }
}

export const getFlexStyle = (style) => {
  const styles = {
    left: {
      justifyContent: 'flex-start',
    },
    center: {
      justifyContent: 'center',
    },
    right: {
      justifyContent: 'flex-end',
    },
  }
  return styles[style]
}

let mouseOverTimeout = null
let overlay = null
let overlayEnabled = true

export const getAnchor = () => {
  const createAnchor = () => {
    const rootContainer = document.createElement('div')
    rootContainer.style.position = 'absolute'
    rootContainer.style.top = 0
    rootContainer.style.left = 0
    rootContainer.style.zIndex = zIndices.tableEllipsis
    rootContainer.setAttribute('id', 'TableEllipsisOverlay')
    document.body.insertBefore(rootContainer, document.body.lastElementChild.nextElementSibling)
    return rootContainer
  }
  return document.querySelector('#TableEllipsisOverlay') || createAnchor()
}

export const reposOverlay = (cell) => {
  if (overlay === null) {
    return
  }

  const expanderRect = cell.getBoundingClientRect()
  const innerWidth = document.documentElement.clientWidth
  const overlayWidth = overlay.getBoundingClientRect().width

  let newLeft = expanderRect.left
  if (newLeft + overlayWidth > innerWidth) {
    newLeft = innerWidth - overlayWidth - 5
  }
  const newTop = expanderRect.top

  overlay.style.left = (newLeft < 0 ? 0 : newLeft) + 'px'
  overlay.style.top = (newTop < 0 ? 0 : newTop) + 'px'
  overlay.style.visibility = 'visible'
}

export const buildCellClone = (source) => {
  let cellContent = source
  while (cellContent !== null && !cellContent.className.match(/rs-table-cell-content( |$)/)) {
    cellContent = cellContent.parentNode
  }
  let cell = cellContent
  while (cell !== null && !cell.className.match(/rs-table-cell( |$)/)) {
    cell = cell.parentNode
  }
  let row = cell
  while (row !== null && !row.className.match(/rs-table-row( |$)/)) {
    row = row.parentNode
  }
  if (cell === null || cellContent === null || row === null) {
    return null
  } else {
    overlay = document.createElement('div')
    overlay.style.height = cell.style.height || cellContent.style.height
    overlay.style.minWidth = '0px'
    overlay.style.width = 'auto'
    overlay.style.visibility = 'hidden'
    overlay.style.pointerEvents = 'none'
    overlay.style.position = 'absolute'
    overlay.style.display = 'flex'
    overlay.style.alignItems = 'center'

    const rowOl = row.cloneNode(false)
    rowOl.style.boxShadow = '0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)'
    rowOl.style.minWidth = '0px'
    rowOl.style.width = 'auto'
    rowOl.style.transform = 'none'
    rowOl.style.position = 'relative'

    const isRowSpanCell = cell.classList.contains('rs-table-cell-rowspan')
    const newCellHeight = isRowSpanCell ? row.style.height : null

    const style = cell.currentStyle || window.getComputedStyle(cell)

    const styleContent = cellContent.currentStyle || window.getComputedStyle(cellContent)
    cell.addEventListener('mouseout', removeOverlay)
    const cellOl = cell.cloneNode(false)
    cellOl.style.backgroundColor = cellContent.style.backgroundColor || style.backgroundColor
    cellOl.style.width = 'auto'
    cellOl.style.paddingRight = '10px'
    cellOl.style.paddingLeft =
      parseInt(styleContent.paddingLeft) > 0 ? parseInt(styleContent.paddingLeft) + 10 + 'px' : '10px'
    cellOl.style.position = 'static'
    cellOl.style.pointerEvents = 'none'
    cellOl.style.height = newCellHeight || cellOl.style.height

    if (cellOl.classList.contains('rs-table-cell-first')) {
      cellOl.classList.remove('rs-table-cell-first')
    }

    const cellContentOl = cellContent.cloneNode(false)
    cellContentOl.style.width = 'auto'
    cellContentOl.style.paddingLeft = '0px'
    cellContentOl.innerHTML = source.innerHTML
    cellContentOl.style.pointerEvents = 'none'
    cellContentOl.style.height = newCellHeight || cellContentOl.style.height

    rowOl.appendChild(cellOl)
    cellOl.appendChild(cellContentOl)
    overlay.appendChild(rowOl)

    setTimeout(() => {
      reposOverlay(cell)
    }, 0)
  }
}

export const addOverlay = (anchor, source) => {
  mouseOverTimeout = null
  buildCellClone(source)
  if (overlay !== null) {
    anchor.appendChild(overlay)
  }
}

export const removeOverlay = () => {
  if (overlay && overlay.parentNode) {
    overlay.parentNode.removeChild(overlay)
  }
  overlay = null
}

export const enableOverlay = (enabled) => {
  overlayEnabled = enabled
  if (!overlayEnabled) {
    removeOverlay()
  }
}

export const mouseOutEllipsis = (evt) => {
  if (mouseOverTimeout !== null) {
    clearTimeout(mouseOverTimeout)
    mouseOverTimeout = null
  }
}

export const mouseOverEllipsis = (evt) => {
  if (!overlayEnabled) {
    return
  }
  const anchor = getAnchor()
  removeOverlay()

  let measureSpan = evt.target
  while (
    measureSpan !== null &&
    (typeof measureSpan.className !== 'string' || measureSpan.className.indexOf('mouseOverSelector') < 0)
  ) {
    measureSpan = measureSpan.parentNode
  }
  if (measureSpan === null) {
    return
  }
  const source = measureSpan.parentNode
  if (source.getBoundingClientRect().width < measureSpan.getBoundingClientRect().width) {
    if (mouseOverTimeout !== null) {
      clearTimeout(mouseOverTimeout)
    }
    mouseOverTimeout = setTimeout(() => {
      addOverlay(anchor, source)
    }, 200)
  }
}

export const getCellBackgroundColor = (evenRow, evenGroup, theme) => {
  if (typeof evenGroup === 'boolean') {
    return evenGroup
      ? evenRow
        ? getThemeValue(theme, 'table.data.evenGroup.evenRow.bg')
        : getThemeValue(theme, 'table.data.evenGroup.oddRow.bg')
      : evenRow
      ? getThemeValue(theme, 'table.data.oddGroup.evenRow.bg')
      : getThemeValue(theme, 'table.data.oddGroup.oddRow.bg')
  }
  return evenRow ? getThemeValue(theme, 'table.data.even.bg') : getThemeValue(theme, 'table.data.odd.bg')
}
